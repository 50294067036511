import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../services/api.service';
import { TokenService } from '../services/token.service';
import { UserService } from '../services/user.service';
import { AppBannerMsgComponent } from '../app-banner-msg/app-banner-msg.component';
import { HttpErrorResponse } from '@angular/common/http';
import { InstitutionService } from '../services/institution.service';
import { TranslateService } from '@ngx-translate/core';
import { User } from '../models/User';
var LoginComponent = /** @class */ (function () {
    function LoginComponent(router, formBuilder, route, apiService, institutionService, userService, tokenService, translateService) {
        this.router = router;
        this.formBuilder = formBuilder;
        this.route = route;
        this.apiService = apiService;
        this.institutionService = institutionService;
        this.userService = userService;
        this.tokenService = tokenService;
        this.translateService = translateService;
    }
    Object.defineProperty(LoginComponent.prototype, "institutionDropdownStyleClass", {
        get: function () {
            var baseClasses = 'ses-dropdown width-100';
            return this.getControlError('institution') ? baseClasses + " error" : baseClasses;
        },
        enumerable: true,
        configurable: true
    });
    LoginComponent.prototype.ngOnInit = function () {
        this.initLoginForm();
        this.subscribeToRouteData();
        this.subscribeToLangChanges();
    };
    LoginComponent.prototype.initLoginForm = function () {
        this.loginForm = this.formBuilder.group({
            institution: ['', Validators.required],
            studentId: ['', Validators.required],
            lastName: ['', Validators.required]
        });
    };
    LoginComponent.prototype.getControlError = function (formControlName) {
        var control = this.loginForm.get(formControlName);
        return control.touched && control.invalid;
    };
    LoginComponent.prototype.getInstitutionSelectItems = function (institutions) {
        return institutions.reduce(function (accumulator, currentValue) {
            accumulator.push({ label: currentValue.institutionAliasName, value: currentValue });
            return accumulator;
        }, [{ label: '', value: null }]);
    };
    LoginComponent.prototype.setLangSpecificErrorMessage = function () {
        var institutionId = this.loginForm.get('institution').value.institutionId;
        var language = this.translateService.currentLang;
        var selectedInstitution = this.institutions.find(function (value) {
            return value.institutionId === institutionId;
        });
        var selectedLanguageSettings = selectedInstitution.configurationTranslationDTOList.find(function (languageObject) {
            return languageObject.languageDTO.value === language;
        });
        this.invalidAccessMessage = selectedLanguageSettings.invalidAccessMessage;
    };
    LoginComponent.prototype.onGetLanguagesError = function () {
        var message = 'Server error retrieving available languages. Contact support if you continue to experience this error.';
        this.bannerMsgComp.addErrorMessage(message);
    };
    LoginComponent.prototype.login = function () {
        var _this = this;
        this.markAllFormFieldsTouched();
        if (this.loginForm.valid) {
            this.loginForm.disable();
            var credentials = this.getLoginCredentials();
            this.apiService.login(credentials).subscribe(function (res) {
                _this.tokenService.token = res.authToken;
                _this.userService.user = _this.parseUser(res.studentFields);
                _this.institutionService.institution = _this.loginForm.get('institution').value;
                _this.router.navigate(['/confirm-user']);
            }, function (err) {
                _this.loginForm.enable();
                if (err instanceof HttpErrorResponse) {
                    if (err.status === 401) {
                        // Extract error information from institutions based on which one is selected and language selected
                        _this.setLangSpecificErrorMessage();
                        _this.showUnauthorizedDialog = true;
                    }
                    else {
                        _this.bannerMsgComp.addErrorMessage('Server error logging on. Contact support if you continue to experience this error.');
                    }
                }
            });
        }
    };
    LoginComponent.prototype.parseUser = function (studentFields) {
        var user = new User();
        user.studentFields = studentFields;
        for (var _i = 0, _a = user.studentFields; _i < _a.length; _i++) {
            var studentField = _a[_i];
            switch (studentField.name) {
                case 'studentId': {
                    user.studentLoginId = studentField.value;
                    studentField.name = 'Student ID:';
                    break;
                }
                case 'studentName': {
                    user.fullName = studentField.value;
                    studentField.name = 'Name:';
                    break;
                }
                case 'birthDate': {
                    user.dateOfBirth = studentField.value;
                    studentField.name = 'Date of Birth:';
                    break;
                }
                case 'gender': {
                    user.gender = studentField.value;
                    studentField.name = 'Gender:';
                    break;
                }
                case 'gradeLevel': {
                    user.gradeLevel = studentField.value;
                    studentField.name = 'Grade Level:';
                    break;
                }
                case 'customerName': {
                    user.institutionName = studentField.value;
                    studentField.name = 'Name of School:';
                    break;
                }
                default: {
                    break;
                }
            }
        }
        return user;
    };
    LoginComponent.prototype.markAllFormFieldsTouched = function () {
        this.loginForm.get('institution').markAsTouched();
        this.loginForm.get('studentId').markAsTouched();
        this.loginForm.get('lastName').markAsTouched();
    };
    LoginComponent.prototype.subscribeToLangChanges = function () {
        var _this = this;
        this.translateService.onLangChange.subscribe(function () {
            _this.loginForm.reset();
        });
    };
    LoginComponent.prototype.subscribeToRouteData = function () {
        var _this = this;
        this.route.data.subscribe(function (data) {
            var institutions = data.institutions;
            if (institutions) {
                _this.institutions = institutions;
                _this.institutionSelectOptions = _this.getInstitutionSelectItems(institutions);
            }
            else {
                var message = 'Server error retrieving institutions. Contact support if you continue to experience this error.';
                _this.bannerMsgComp.addErrorMessage(message);
            }
        });
    };
    LoginComponent.prototype.getLoginCredentials = function () {
        return {
            adminInstitutionId: this.loginForm.get('institution').value.institutionId,
            loginId: this.loginForm.get('studentId').value,
            lastNameChars: this.loginForm.get('lastName').value
        };
    };
    LoginComponent.prototype.clearAndCloseError = function () {
        this.showUnauthorizedDialog = false;
        this.loginForm.reset();
    };
    return LoginComponent;
}());
export { LoginComponent };
