import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectItem } from 'primeng/api';
import { IInstitution } from '../models/IInstitution';
import { ApiService } from '../services/api.service';
import { TokenService } from '../services/token.service';
import { UserService } from '../services/user.service';
import { AppBannerMsgComponent } from '../app-banner-msg/app-banner-msg.component';
import { HttpErrorResponse } from '@angular/common/http';
import { InstitutionService } from '../services/institution.service';
import { TranslateService } from '@ngx-translate/core';
import { User } from '../models/User';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})

export class LoginComponent implements OnInit {
  @ViewChild(AppBannerMsgComponent) private bannerMsgComp: AppBannerMsgComponent;
  loginForm: FormGroup;
  institutions: IInstitution[];
  institutionSelectOptions: SelectItem[];
  showUnauthorizedDialog: boolean;
  invalidAccessMessage: string;

  constructor(private router: Router, private formBuilder: FormBuilder, private route: ActivatedRoute, private apiService: ApiService,
              private institutionService: InstitutionService, private userService: UserService, private tokenService: TokenService,
              private translateService: TranslateService) { }

  get institutionDropdownStyleClass() {
    const baseClasses = 'ses-dropdown width-100';
    return this.getControlError('institution') ? `${baseClasses} error` : baseClasses;
  }

  ngOnInit() {
    this.initLoginForm();
    this.subscribeToRouteData();
    this.subscribeToLangChanges();
  }

  initLoginForm() {
    this.loginForm = this.formBuilder.group({
      institution: ['', Validators.required],
      studentId: ['', Validators.required],
      lastName: ['', Validators.required]
    });
  }

  getControlError(formControlName: string): boolean {
    const control = this.loginForm.get(formControlName);
    return control.touched && control.invalid;
  }

  getInstitutionSelectItems(institutions: IInstitution[]): SelectItem[] {
    return institutions.reduce((accumulator, currentValue) => {
      accumulator.push({label: currentValue.institutionAliasName, value: currentValue});
      return accumulator;
    }, [{label: '', value: null}]);
  }

  setLangSpecificErrorMessage() {
    const institutionId = this.loginForm.get('institution').value.institutionId;
    const language = this.translateService.currentLang;
    const selectedInstitution: IInstitution = this.institutions.find(value => {
      return value.institutionId === institutionId;
    });
    const selectedLanguageSettings = selectedInstitution.configurationTranslationDTOList.find(languageObject => {
      return languageObject.languageDTO.value === language;
    });
    this.invalidAccessMessage = selectedLanguageSettings.invalidAccessMessage;
  }

  onGetLanguagesError() {
    const message = 'Server error retrieving available languages. Contact support if you continue to experience this error.';
    this.bannerMsgComp.addErrorMessage(message);
  }

  login() {
    this.markAllFormFieldsTouched();
    if (this.loginForm.valid) {
      this.loginForm.disable();
      const credentials = this.getLoginCredentials();
      this.apiService.login(credentials).subscribe(
        res => {
          this.tokenService.token = res.authToken;
          this.userService.user = this.parseUser(res.studentFields);
          this.institutionService.institution = this.loginForm.get('institution').value;
          this.router.navigate(['/confirm-user']);
        },
        err => {
          this.loginForm.enable();
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              // Extract error information from institutions based on which one is selected and language selected
              this.setLangSpecificErrorMessage();
              this.showUnauthorizedDialog = true;
            } else {
              this.bannerMsgComp.addErrorMessage('Server error logging on. Contact support if you continue to experience this error.');
            }
          }
        }
      );
    }
  }

  parseUser(studentFields): User {
    const user = new User();
    user.studentFields = studentFields;
    for (const studentField of user.studentFields) {
      switch (studentField.name) {
        case 'studentId': {
          user.studentLoginId = studentField.value;
          studentField.name = 'Student ID:';
          break;
        }
        case 'studentName': {
          user.fullName = studentField.value;
          studentField.name = 'Name:';
          break;
        }
        case 'birthDate': {
          user.dateOfBirth = studentField.value;
          studentField.name = 'Date of Birth:';
          break;
        }
        case 'gender': {
          user.gender = studentField.value;
          studentField.name = 'Gender:';
          break;
        }
        case 'gradeLevel': {
          user.gradeLevel = studentField.value;
          studentField.name = 'Grade Level:';
          break;
        }
        case 'customerName': {
          user.institutionName = studentField.value;
          studentField.name = 'Name of School:';
          break;
        }
        default: {
          break;
        }
      }
    }

    return user;
  }

  markAllFormFieldsTouched() {
    this.loginForm.get('institution').markAsTouched();
    this.loginForm.get('studentId').markAsTouched();
    this.loginForm.get('lastName').markAsTouched();
  }

  private subscribeToLangChanges() {
    this.translateService.onLangChange.subscribe(() => {
      this.loginForm.reset();
    });
  }

  private subscribeToRouteData() {
    this.route.data.subscribe(data => {
      const institutions = data.institutions;
      if (institutions) {
        this.institutions = institutions;
        this.institutionSelectOptions = this.getInstitutionSelectItems(institutions);
      } else {
        const message = 'Server error retrieving institutions. Contact support if you continue to experience this error.';
        this.bannerMsgComp.addErrorMessage(message);
      }
    });
  }

  private getLoginCredentials() {
    return {
      adminInstitutionId: this.loginForm.get('institution').value.institutionId,
      loginId: this.loginForm.get('studentId').value,
      lastNameChars: this.loginForm.get('lastName').value
    };
  }

  public clearAndCloseError() {
      this.showUnauthorizedDialog = false;
      this.loginForm.reset();
  }
}
