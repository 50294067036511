import { of as observableOf } from 'rxjs';
import { delay, tap } from 'rxjs/operators';
var AppBannerMsgComponent = /** @class */ (function () {
    function AppBannerMsgComponent() {
        this._messages = [];
        this._state = 'active';
        this._isMessagesVisible = true;
    }
    Object.defineProperty(AppBannerMsgComponent.prototype, "isMessagesVisible", {
        get: function () {
            return this._isMessagesVisible;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppBannerMsgComponent.prototype, "messages", {
        get: function () {
            return this._messages;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppBannerMsgComponent.prototype, "state", {
        get: function () {
            return this._state;
        },
        enumerable: true,
        configurable: true
    });
    AppBannerMsgComponent.prototype.onMessagesClosed = function () {
        this._isMessagesVisible = false;
        this._messages.length = 0;
        this.unsubscribeFromMessage();
    };
    AppBannerMsgComponent.prototype.addErrorMessage = function (summary) {
        this._messages.push({ summary: summary, severity: 'error' });
        this.messageSubscription$ = this.showMessages().subscribe();
    };
    AppBannerMsgComponent.prototype.unsubscribeFromMessage = function () {
        if (this.messageSubscription$) {
            this.messageSubscription$.unsubscribe();
        }
    };
    AppBannerMsgComponent.prototype.showMessages = function () {
        var _this = this;
        this._state = 'active';
        this._isMessagesVisible = true;
        return observableOf('').pipe(delay(AppBannerMsgComponent.MESSAGE_REMOVE_RELAY - 1000), tap(function () {
            if (_this.messages.length === 1) {
                _this._state = 'inactive';
            }
        }), delay(1000), tap(function () { return _this.messages.shift(); }));
    };
    AppBannerMsgComponent.MESSAGE_REMOVE_RELAY = 8000; // remove the message after 8 seconds
    return AppBannerMsgComponent;
}());
export { AppBannerMsgComponent };
