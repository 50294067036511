import { Component, Input, OnChanges } from '@angular/core';
import { SimpleChanges } from '@angular/core/src/metadata/lifecycle_hooks';

@Component({
  selector: 'app-form-error-message',
  templateUrl: './form-error-message.component.html',
  styleUrls: ['./form-error-message.component.scss']
})

export class FormErrorMessageComponent implements OnChanges {
  @Input() show: boolean;

  ngOnChanges(changes: SimpleChanges) {
    this.show = changes.show ? changes.show.currentValue : false;
  }

}
