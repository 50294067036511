import { Injectable } from '@angular/core';
import { User } from '../models/User';

@Injectable()
export class UserService {
  set user(user: User) {
    localStorage.setItem('user', JSON.stringify(user));
  }

  get user(): User {
    const userJSON = JSON.parse(localStorage.getItem('user'));

    const user = new User();
    user.parseUser(userJSON);
    return user;
  }
}
