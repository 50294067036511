<app-portal-header></app-portal-header>
<app-banner-msg></app-banner-msg>
<div class="main-content">
  <app-lang-setting (getLanguagesError)="onGetLanguagesError()"></app-lang-setting>
  <div class="section-centered width-410px">
    <div class="auth-title">
      <i class="eleot-icon icon-lock branded"></i>
      <span auto="secure-student">{{"Secure Student Surveys" | translate}}</span>
    </div>
    <form [formGroup]="loginForm" (ngSubmit)="login()" class="auth-form login-container">
      <div class="input-error-container">
        <label class="default" for="institution">{{"Select your State:" | translate}}</label>
        <p-dropdown [styleClass]="institutionDropdownStyleClass"
                    auto="state-selector"
                    id="institution" [options]="institutionSelectOptions"
                    dropdownIcon="eleot-icon icon-chevron-down"
                    formControlName="institution" [autoWidth]="false">
        </p-dropdown>
        <app-form-error-message [show]="getControlError('institution')">
          {{"Please select a state." | translate}}
        </app-form-error-message>
      </div>
      <div class="input-error-container">
        <label class="default" for="studentId">{{"Login ID:" | translate}}</label>
        <input class="default width-100" [class.error]="getControlError('studentId')"
               type="text" id="studentId" formControlName="studentId" autocomplete="off">
        <app-form-error-message [show]="getControlError('studentId')">
          {{"Please enter a valid login id." | translate}}
        </app-form-error-message>
      </div>
      <div class="input-error-container">
        <div class="flex align-top">
          <label class="default width-75" for="lastName">
            {{"Enter the first three characters of your last name:" | translate}}
          </label>
          <input auto="last-name" class="default width-25" [class.error]="getControlError('lastName')"
                 id="lastName" type="text" formControlName="lastName" autocomplete="off">
        </div>
        <div class="input-subtext">{{'(Example - Joe Smith = Smi or Brian Wu = Wu)' | translate}}</div>
        <app-form-error-message [show]="getControlError('lastName')">
          {{"Please enter a valid last name." | translate}}
        </app-form-error-message>
      </div>
      <button auto="login-btn" class="width-100" type="submit" (click)="markAllFormFieldsTouched()">
        {{'Login' | translate}}
      </button>
    </form>
  </div>
</div>

<p-dialog width="460" [(visible)]="showUnauthorizedDialog" [modal]="true" styleClass="ses-dialog">
  <p-header>
    <i class="fas fa-exclamation-triangle"></i>
    <span>{{"Invalid Access" | translate}}</span>
  </p-header>
  <strong>{{invalidAccessMessage}}</strong>
  <p-footer>
    <button (click)="clearAndCloseError()">OK</button>
  </p-footer>
</p-dialog>
