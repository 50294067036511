<app-idle-timer [inactiveMinutes]="120"></app-idle-timer>
<app-banner-msg></app-banner-msg>
<app-header></app-header>
<div class="main-content">
  <app-lang-setting (getLanguagesError)="onGetLanguagesError()"></app-lang-setting>
  <header auto="ses-dash-header" >{{"My Surveys" | translate}}</header>
  <p-table auto="ses-dash-table" [value]="surveys" styleClass="ses-table ses-dashboard-table"
           [loading]="isLoading" loadingIcon="fa fa-spinner fa-spin">
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="4" class="ui-table-empty-message">
          <span class="hide-while-loading-message">{{"No Surveys Available" | translate}}</span>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-survey>
      <tr class="ses-dashboard-row">
        <td>
          <div class="dashboard-cell bold">
            {{survey.surveyName}}
          </div>
        </td>
	    <td>
		  <div class="dashboard-cell bold">
			  {{survey.institutionName}}
		  </div>
	    </td>
        <td class="action-column">
          <div *ngIf="!survey.surveyCompleted; else surveyComplete">
            <a [href]="survey.surveyTakingUrl" class="start-survey">
              <span class="bold action-text">{{"Start Survey" | translate}}</span>
              <i class="fas fa-play-circle"></i>
            </a>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>

<ng-template #surveyComplete>
  <i class="far fa-check-circle"></i>
  <span class="bold action-text">{{"Completed Survey" | translate}}</span>
</ng-template>
