import { Component, OnInit, Output, EventEmitter, Inject, OnDestroy } from '@angular/core';
import { ApiService } from '../services/api.service';
import { SelectItem } from 'primeng/api';
import { ILanguage } from '../models/ILanguage';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { APP_CONFIG, AppConfig } from '../app-config.module';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-lang-setting',
  templateUrl: './lang-setting.component.html'
})

export class LangSettingComponent implements OnInit, OnDestroy {
  @Output() getLanguagesError = new EventEmitter<any>();
  languages: SelectItem[];
  selectedLanguage: SelectItem;

  constructor(@Inject(APP_CONFIG)
    private appConfig: AppConfig,
    private cookieService: CookieService,
    private apiService: ApiService, private translateService: TranslateService) { }
    private languageSubscription: Subscription;

  ngOnInit() {
    this.languageSubscription = this.apiService.languageSubject.subscribe(
      res => {
        // Only process if the response exist
        if (res) {
            const storedLanguage = this.cookieService.get(this.appConfig.LANGUAGE_COOKIE_KEY);
            this.languages = this.getLanguageSelectItems(res);
            this.selectedLanguage = this.languages.find(language => {
                return language.value.value === storedLanguage;
            });
        }
      },
      err => {
        this.getLanguagesError.emit();
      }
    );
    this.apiService.getLanguages();
  }

  ngOnDestroy() {
    if (this.languageSubscription) {
        this.languageSubscription.unsubscribe();
    }
  }

  setLanguage(lang: SelectItem) {
    this.selectedLanguage = lang;
    this.cookieService.set(this.appConfig.LANGUAGE_COOKIE_KEY, lang.value.value);
    this.translateService.use(lang.value.value);
  }

  isLanguageSelected(lang: SelectItem) {
    const selected = this.selectedLanguage;
    return lang && selected ? lang.value.value === selected.value.value : false;
  }

  private getLanguageSelectItems(languages: ILanguage[]): SelectItem[] {
    return languages.reduce((accumulator, currentValue) => {
      accumulator.push({ label: currentValue.name, value: currentValue });
      return accumulator;
    }, []);
  }
}
