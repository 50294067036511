/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./version.component";
var styles_VersionComponent = ["div[_ngcontent-%COMP%] { font-weight: bold; margin: 10px 20px; }"];
var RenderType_VersionComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_VersionComponent, data: {} });
export { RenderType_VersionComponent as RenderType_VersionComponent };
export function View_VersionComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i1.DatePipe, [i0.LOCALE_ID]), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["Build Date: ", ""])), i0.ɵppd(3, 2), (_l()(), i0.ɵeld(4, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, ["Version: ", ""])), (_l()(), i0.ɵeld(6, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, ["Revision: ", ""])), (_l()(), i0.ɵeld(8, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(9, null, ["Branch: ", ""])), (_l()(), i0.ɵeld(10, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(11, null, ["Build Env: ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i0.ɵnov(_v, 0), _co.versionInfo.date, "medium")); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.versionInfo.version; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.versionInfo.revision; _ck(_v, 7, 0, currVal_2); var currVal_3 = _co.versionInfo.branch; _ck(_v, 9, 0, currVal_3); var currVal_4 = _co.buildEnv; _ck(_v, 11, 0, currVal_4); }); }
export function View_VersionComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-version", [], null, null, null, View_VersionComponent_0, RenderType_VersionComponent)), i0.ɵdid(1, 49152, null, 0, i2.VersionComponent, [], null, null)], null, null); }
var VersionComponentNgFactory = i0.ɵccf("app-version", i2.VersionComponent, View_VersionComponent_Host_0, {}, {}, []);
export { VersionComponentNgFactory as VersionComponentNgFactory };
