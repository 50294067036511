<div class="app-header">
  <div class="header-item"></div>
  <div class="header-item app-logo">
    <img src="assets/images/logo-appbar-surveys.svg" alt="eprove&trade; surveys">
  </div>
  <div class="header-item menu-container">
    <div class="menu-toggle">
      <button auto="welcome-btn" class="btn-styleless" type="button" pButton (click)="menu.toggle($event)">
        <span class="welcome-txt">{{'Welcome,' | translate}}</span>
        <span>{{" " + user.firstName}}</span>
        <i class="fas fa-caret-down"></i>
      </button>
      <p-menu #menu styleClass="menu-user-logout" popup="popup" [model]="items" appendTo="body"></p-menu>
    </div>
  </div>
</div>
