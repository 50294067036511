import { Component } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Observable, Subscription, of as observableOf } from 'rxjs';
import { delay, tap } from 'rxjs/operators';
import { Message } from 'primeng/primeng';

@Component({
  selector: 'app-banner-msg',
  templateUrl: './app-banner-msg.component.html',
  styleUrls: ['./app-banner-msg.component.scss'],
  animations: [
    trigger('bannerState', [
      state('inactive', style({
        visibility: 'hidden',
        opacity: 0,
        height: 0
      })),
      state('active',   style({
        visibility: 'visible',
        opacity: 1,
      })),
      transition('inactive => active', animate('500ms ease-in')),
      transition('active => inactive', animate('500ms ease-out'))
    ])
  ]
})

export class AppBannerMsgComponent {
  private static MESSAGE_REMOVE_RELAY = 8000; // remove the message after 8 seconds
  private _messages: Message[] = [];
  private _state = 'active';
  private _isMessagesVisible = true;
  private messageSubscription$: Subscription;

  get isMessagesVisible() {
    return this._isMessagesVisible;
  }

  get messages() {
    return this._messages;
  }

  get state() {
    return this._state;
  }

  onMessagesClosed() {
    this._isMessagesVisible = false;
    this._messages.length = 0;
    this.unsubscribeFromMessage();
  }

  addErrorMessage(summary: string) {
    this._messages.push({summary: summary, severity: 'error'});
    this.messageSubscription$ = this.showMessages().subscribe();
  }

  private unsubscribeFromMessage(): void {
    if (this.messageSubscription$) {
      this.messageSubscription$.unsubscribe();
    }
  }

  private showMessages(): Observable<string> {
    this._state = 'active';
    this._isMessagesVisible = true;
    return observableOf('').pipe(
      delay(AppBannerMsgComponent.MESSAGE_REMOVE_RELAY - 1000),
      tap(() => {
        if (this.messages.length === 1) {
          this._state = 'inactive';
        }
      }),
      delay(1000),
      tap(() => this.messages.shift()));
  }

}
