import { Component} from '@angular/core';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-version',
  template:
    '<div>Build Date: {{versionInfo.date | date:\'medium\'}}</div>' +
    '<div>Version: {{versionInfo.version}}</div>' +
    '<div>Revision: {{versionInfo.revision}}</div>' +
    '<div>Branch: {{versionInfo.branch}}</div>' +
    '<div>Build Env: {{buildEnv}}</div>',
  styles: ['div { font-weight: bold; margin: 10px 20px; }']
})

export class VersionComponent {
  versionInfo;
  buildEnv = '';

  constructor() {
    this.buildEnv = environment.envname;
    if (environment.version && environment.version.date) {
      this.versionInfo = environment.version;
    } else {
      this.versionInfo = 'No Version Available Run Npm Build Or Version Script';
    }
  }
}
