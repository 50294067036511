/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./confirm-user.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../idle-timer/idle-timer.component.ngfactory";
import * as i4 from "../idle-timer/idle-timer.component";
import * as i5 from "@angular/router";
import * as i6 from "../services/api.service";
import * as i7 from "../portal-header/portal-header.component.ngfactory";
import * as i8 from "../portal-header/portal-header.component";
import * as i9 from "../app-banner-msg/app-banner-msg.component.ngfactory";
import * as i10 from "../app-banner-msg/app-banner-msg.component";
import * as i11 from "../lang-setting/lang-setting.component.ngfactory";
import * as i12 from "../lang-setting/lang-setting.component";
import * as i13 from "../app-config.module";
import * as i14 from "ngx-cookie-service/cookie-service/cookie.service";
import * as i15 from "@angular/common";
import * as i16 from "../../../node_modules/primeng/components/dialog/dialog.ngfactory";
import * as i17 from "primeng/components/dom/domhandler";
import * as i18 from "primeng/components/dialog/dialog";
import * as i19 from "../../../node_modules/primeng/components/common/shared.ngfactory";
import * as i20 from "primeng/components/common/shared";
import * as i21 from "./confirm-user.component";
import * as i22 from "../services/user.service";
import * as i23 from "../services/institution.service";
var styles_ConfirmUserComponent = [i0.styles];
var RenderType_ConfirmUserComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConfirmUserComponent, data: {} });
export { RenderType_ConfirmUserComponent as RenderType_ConfirmUserComponent };
function View_ConfirmUserComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_v.context.$implicit.name)); _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.value; _ck(_v, 5, 0, currVal_1); }); }
export function View_ConfirmUserComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { bannerMsgComp: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-idle-timer", [], null, [["document", "mousemove"], ["document", "keypress"], ["document", "click"]], function (_v, en, $event) { var ad = true; if (("document:mousemove" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).userAction() !== false);
        ad = (pd_0 && ad);
    } if (("document:keypress" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).userAction() !== false);
        ad = (pd_1 && ad);
    } if (("document:click" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2).userAction() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i3.View_IdleTimerComponent_0, i3.RenderType_IdleTimerComponent)), i1.ɵdid(2, 114688, null, 0, i4.IdleTimerComponent, [i5.Router, i6.ApiService], { inactiveMinutes: [0, "inactiveMinutes"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-portal-header", [], null, null, null, i7.View_PortalHeaderComponent_0, i7.RenderType_PortalHeaderComponent)), i1.ɵdid(4, 49152, null, 0, i8.PortalHeaderComponent, [], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-banner-msg", [], null, null, null, i9.View_AppBannerMsgComponent_0, i9.RenderType_AppBannerMsgComponent)), i1.ɵdid(6, 49152, [[1, 4]], 0, i10.AppBannerMsgComponent, [], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 24, "div", [["class", "main-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "app-lang-setting", [], null, [[null, "getLanguagesError"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("getLanguagesError" === en)) {
        var pd_0 = (_co.onGetLanguagesError() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i11.View_LangSettingComponent_0, i11.RenderType_LangSettingComponent)), i1.ɵdid(9, 245760, null, 0, i12.LangSettingComponent, [i13.APP_CONFIG, i14.CookieService, i6.ApiService, i2.TranslateService], null, { getLanguagesError: "getLanguagesError" }), (_l()(), i1.ɵeld(10, 0, null, null, 21, "div", [["class", "section-centered width-420px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 4, "div", [["class", "auth-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 0, "i", [["class", "eleot-icon icon-lock branded"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(14, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(16, 0, null, null, 2, "div", [["class", "subtitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(17, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(19, 0, null, null, 2, "ul", [["auto", "info-container"], ["class", "style-reset"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfirmUserComponent_1)), i1.ɵdid(21, 802816, null, 0, i15.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(22, 0, null, null, 2, "div", [["class", "action-context"]], null, null, null, null, null)), (_l()(), i1.ɵted(23, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(25, 0, null, null, 6, "div", [["class", "flex space-between btn-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(26, 0, null, null, 2, "button", [["auto", "info-no-btn"], ["class", "button-cancel"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.showDialog = true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(27, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(29, 0, null, null, 2, "button", [["auto", "info-yes-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onConfirmInformation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(30, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(32, 0, null, null, 19, "p-dialog", [["styleClass", "ses-dialog"], ["width", "460"]], null, [[null, "visibleChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("visibleChange" === en)) {
        var pd_0 = ((_co.showDialog = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i16.View_Dialog_0, i16.RenderType_Dialog)), i1.ɵprd(512, null, i17.DomHandler, i17.DomHandler, []), i1.ɵdid(34, 12763136, null, 2, i18.Dialog, [i1.ElementRef, i17.DomHandler, i1.Renderer2, i1.NgZone], { width: [0, "width"], modal: [1, "modal"], styleClass: [2, "styleClass"], visible: [3, "visible"] }, { visibleChange: "visibleChange" }), i1.ɵqud(603979776, 2, { headerFacet: 1 }), i1.ɵqud(603979776, 3, { footerFacet: 1 }), (_l()(), i1.ɵeld(37, 0, null, 0, 5, "p-header", [], null, null, null, i19.View_Header_0, i19.RenderType_Header)), i1.ɵdid(38, 49152, [[2, 4]], 0, i20.Header, [], null, null), (_l()(), i1.ɵeld(39, 0, null, 0, 0, "i", [["class", "fas fa-exclamation-triangle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(40, 0, null, 0, 2, "span", [["auto", "not-confirmed-modal"]], null, null, null, null, null)), (_l()(), i1.ɵted(41, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(43, 1, [" ", " "])), (_l()(), i1.ɵeld(44, 0, null, 2, 7, "p-footer", [], null, null, null, i19.View_Footer_0, i19.RenderType_Footer)), i1.ɵdid(45, 49152, [[3, 4]], 0, i20.Footer, [], null, null), (_l()(), i1.ɵeld(46, 0, null, 0, 2, "button", [["auto", "not-confirmed-cancel-btn"], ["class", "button-cancel"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.showDialog = false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(47, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(49, 0, null, 0, 2, "button", [["auto", "not-confirmed-backtologin-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onDialogBack() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(50, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = 20; _ck(_v, 2, 0, currVal_0); _ck(_v, 9, 0); var currVal_3 = _co.user.studentFields; _ck(_v, 21, 0, currVal_3); var currVal_7 = "460"; var currVal_8 = true; var currVal_9 = "ses-dialog"; var currVal_10 = _co.showDialog; _ck(_v, 34, 0, currVal_7, currVal_8, currVal_9, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 14, 0, i1.ɵnov(_v, 15).transform("Secure Student Surveys")); _ck(_v, 14, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 17, 0, i1.ɵnov(_v, 18).transform("Please confirm your information")); _ck(_v, 17, 0, currVal_2); var currVal_4 = i1.ɵunv(_v, 23, 0, i1.ɵnov(_v, 24).transform("Is this you?")); _ck(_v, 23, 0, currVal_4); var currVal_5 = i1.ɵunv(_v, 27, 0, i1.ɵnov(_v, 28).transform("No")); _ck(_v, 27, 0, currVal_5); var currVal_6 = i1.ɵunv(_v, 30, 0, i1.ɵnov(_v, 31).transform("Yes")); _ck(_v, 30, 0, currVal_6); var currVal_11 = i1.ɵunv(_v, 41, 0, i1.ɵnov(_v, 42).transform("Not Confirmed")); _ck(_v, 41, 0, currVal_11); var currVal_12 = _co.contactErrorMessage; _ck(_v, 43, 0, currVal_12); var currVal_13 = i1.ɵunv(_v, 47, 0, i1.ɵnov(_v, 48).transform("Cancel")); _ck(_v, 47, 0, currVal_13); var currVal_14 = i1.ɵunv(_v, 50, 0, i1.ɵnov(_v, 51).transform("Back to Login")); _ck(_v, 50, 0, currVal_14); }); }
export function View_ConfirmUserComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-confirm-user", [], null, null, null, View_ConfirmUserComponent_0, RenderType_ConfirmUserComponent)), i1.ɵdid(1, 114688, null, 0, i21.ConfirmUserComponent, [i13.APP_CONFIG, i5.Router, i22.UserService, i14.CookieService, i23.InstitutionService, i2.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ConfirmUserComponentNgFactory = i1.ɵccf("app-confirm-user", i21.ConfirmUserComponent, View_ConfirmUserComponent_Host_0, {}, {}, []);
export { ConfirmUserComponentNgFactory as ConfirmUserComponentNgFactory };
