import { Injectable } from '@angular/core';
import { IInstitution } from '../models/IInstitution';

@Injectable()
export class InstitutionService {
  set institution(institution: IInstitution) {
    localStorage.setItem('institution', JSON.stringify(institution));
  }

  get institution(): IInstitution {
    return JSON.parse(localStorage.getItem('institution'));
  }
}
