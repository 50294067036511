<p-dropdown [options]="languages"
            auto="language-dropdown"
            styleClass="dropdown-language-setting"
            [autoWidth]="false">
  <ng-template let-item pTemplate="selectedItem">
      <i class="eleot-icon icon-settings"></i>
      {{ selectedLanguage.value.instructions}}
      <i class="eleot-icon icon-chevron-down"></i>
  </ng-template>
  <ng-template let-language pTemplate="item">
    <div (click)="setLanguage(language)" class="flex space-between" [class.branded]="isLanguageSelected(language)">
      {{language.label}}
      <app-faux-radio-btn [selected]="isLanguageSelected(language)"></app-faux-radio-btn>
    </div>
  </ng-template>
</p-dropdown>
