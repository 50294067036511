import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../services/user.service';
import { User } from '../models/User';
import { AppBannerMsgComponent } from '../app-banner-msg/app-banner-msg.component';
import { InstitutionService } from '../services/institution.service';
import { IInstitution } from '../models/IInstitution';
import { CookieService } from 'ngx-cookie-service';
import { APP_CONFIG, AppConfig } from '../app-config.module';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-confirm-user',
  templateUrl: './confirm-user.component.html',
  styleUrls: ['./confirm-user.component.scss']
})

export class ConfirmUserComponent implements OnInit {
  @ViewChild(AppBannerMsgComponent) bannerMsgComp: AppBannerMsgComponent;
  showDialog = false;
  user: User;
  institution: IInstitution;
  language: string;
  contactErrorMessage: string;

  constructor(@Inject(APP_CONFIG)
  private appConfig: AppConfig,
    private router: Router,
    private userService: UserService,
    private cookieService: CookieService,
    private institutionService: InstitutionService,
    private translateService: TranslateService) { }

  ngOnInit() {
    this.user = this.userService.user;
    this.institution = this.institutionService.institution;
    this.language = this.cookieService.get(this.appConfig.LANGUAGE_COOKIE_KEY);
    this.initContactMessage();
    this.translateService.onLangChange.subscribe(() => {
      this.language = this.cookieService.get(this.appConfig.LANGUAGE_COOKIE_KEY);
      this.initContactMessage();
    });
  }

  initContactMessage() {
    const config = this.institution.configurationTranslationDTOList.find(
      dto => dto.languageDTO.value === this.language
    );
    if (config) { this.contactErrorMessage = config.contactMessage; }
  }

  onConfirmInformation() {
    this.router.navigate(['/dashboard']);
  }

  onDialogBack() {
    this.router.navigate(['/login']);
  }

  onGetLanguagesError() {
    const message = 'Server error retrieving available languages. Contact support if you continue to experience this error.';
    this.bannerMsgComp.addErrorMessage(message);
  }
}
