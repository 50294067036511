/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app-header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../../../node_modules/primeng/components/menu/menu.ngfactory";
import * as i4 from "primeng/components/dom/domhandler";
import * as i5 from "primeng/components/menu/menu";
import * as i6 from "./app-header.component";
import * as i7 from "../app-config.module";
import * as i8 from "@angular/router";
import * as i9 from "../services/user.service";
import * as i10 from "../services/api.service";
import * as i11 from "ngx-cookie-service/cookie-service/cookie.service";
var styles_AppHeaderComponent = [i0.styles];
var RenderType_AppHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppHeaderComponent, data: {} });
export { RenderType_AppHeaderComponent as RenderType_AppHeaderComponent };
export function View_AppHeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "app-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "header-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "header-item app-logo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["alt", "eprove\u2122 surveys"], ["src", "assets/images/logo-appbar-surveys.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 11, "div", [["class", "header-item menu-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 10, "div", [["class", "menu-toggle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 6, "button", [["auto", "welcome-btn"], ["class", "btn-styleless"], ["pButton", ""], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 15).toggle($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "span", [["class", "welcome-txt"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(10, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["", ""])), (_l()(), i1.ɵeld(12, 0, null, null, 0, "i", [["class", "fas fa-caret-down"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 2, "p-menu", [["appendTo", "body"], ["popup", "popup"], ["styleClass", "menu-user-logout"]], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 15).onResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_Menu_0, i3.RenderType_Menu)), i1.ɵprd(512, null, i4.DomHandler, i4.DomHandler, []), i1.ɵdid(15, 4374528, [["menu", 4]], 0, i5.Menu, [i1.ElementRef, i4.DomHandler, i1.Renderer2], { model: [0, "model"], popup: [1, "popup"], styleClass: [2, "styleClass"], appendTo: [3, "appendTo"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.items; var currVal_3 = "popup"; var currVal_4 = "menu-user-logout"; var currVal_5 = "body"; _ck(_v, 15, 0, currVal_2, currVal_3, currVal_4, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform("Welcome,")); _ck(_v, 8, 0, currVal_0); var currVal_1 = (" " + _co.user.firstName); _ck(_v, 11, 0, currVal_1); }); }
export function View_AppHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header", [], null, null, null, View_AppHeaderComponent_0, RenderType_AppHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i6.AppHeaderComponent, [i7.APP_CONFIG, i8.Router, i2.TranslateService, i9.UserService, i10.ApiService, i11.CookieService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppHeaderComponentNgFactory = i1.ɵccf("app-header", i6.AppHeaderComponent, View_AppHeaderComponent_Host_0, { actionLeft: "actionLeft" }, {}, []);
export { AppHeaderComponentNgFactory as AppHeaderComponentNgFactory };
