import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { IInstitution } from '../models/IInstitution';
import { environment } from '../../environments/environment';
import { ISurvey } from '../models/ISurvey';
import { ILanguage } from '../models/ILanguage';
import 'rxjs/add/observable/of';
import { StudentField } from '../models/StudentField';

@Injectable()
export class ApiService {

  private baseUrl = environment.baseUrl;
  private cachedLanguagesAvailable: Boolean = false;
  private cachedLanguagesSubscription: Subscription = null;
  public languageSubject: BehaviorSubject<ILanguage[]> = new BehaviorSubject<ILanguage[]>([]);

  constructor(private http: HttpClient) { }

  login(
      credentials: {adminInstitutionId: number, loginId: string, lastNameChars: string}
    ): Observable<{authToken: string, studentFields: StudentField[]}> {
    return this.http.post<{authToken: string, studentFields: StudentField[]}>(`${this.baseUrl}/student-login`, credentials);
  }

  getSurveys(): Observable<ISurvey[]> {
    return this.http.get<ISurvey[]>(`${this.baseUrl}/dashboard`);
  }

  logout(): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/logout`, {});
  }

  getLanguages() {
    if (!this.cachedLanguagesAvailable) {
        // Execute API and observer locally and update behavior subject
        //  that the downstream components are subscribed to
        this.cachedLanguagesSubscription = this.http.get<ILanguage[]>(`${this.baseUrl}/configurations/languages`).subscribe((result) => {
            this.cachedLanguagesAvailable = true;
            this.languageSubject.next(result);
            this.cachedLanguagesSubscription.unsubscribe();
        });
    }
  }

  getInstitutions(): Observable<IInstitution[]> {
    return this.http.get<IInstitution[]>(`${this.baseUrl}/configurations/admin-institutions`);
  }
}
