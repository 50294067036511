import { Component, Inject, OnInit } from '@angular/core';
import { APP_CONFIG, AppConfig } from './app-config.module';

// services
import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})

export class AppComponent implements OnInit {
  constructor(@Inject(APP_CONFIG)
  private appConfig: AppConfig,
    private translateService: TranslateService,
    private cookieService: CookieService
  ) { }


  ngOnInit() {
    const languageCookie = this.cookieService.get(this.appConfig.LANGUAGE_COOKIE_KEY);

    if (languageCookie) {
      this.translateService.use(languageCookie);
    } else {
      this.cookieService.set(this.appConfig.LANGUAGE_COOKIE_KEY, this.appConfig.DEFAULT_LANGUAGE);
      this.translateService.use(this.appConfig.DEFAULT_LANGUAGE);
    }

  }

}
