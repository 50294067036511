/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../app-faux-radio-btn/faux-radio-btn.component.ngfactory";
import * as i2 from "../app-faux-radio-btn/faux-radio-btn.component";
import * as i3 from "../../../node_modules/primeng/components/dropdown/dropdown.ngfactory";
import * as i4 from "@angular/forms";
import * as i5 from "primeng/components/dropdown/dropdown";
import * as i6 from "primeng/components/dom/domhandler";
import * as i7 from "primeng/components/utils/objectutils";
import * as i8 from "primeng/components/common/shared";
import * as i9 from "./lang-setting.component";
import * as i10 from "../app-config.module";
import * as i11 from "ngx-cookie-service/cookie-service/cookie.service";
import * as i12 from "../services/api.service";
import * as i13 from "@ngx-translate/core";
var styles_LangSettingComponent = [];
var RenderType_LangSettingComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LangSettingComponent, data: {} });
export { RenderType_LangSettingComponent as RenderType_LangSettingComponent };
function View_LangSettingComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "i", [["class", "eleot-icon icon-settings"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "])), (_l()(), i0.ɵeld(2, 0, null, null, 0, "i", [["class", "eleot-icon icon-chevron-down"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectedLanguage.value.instructions; _ck(_v, 1, 0, currVal_0); }); }
function View_LangSettingComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "flex space-between"]], [[2, "branded", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setLanguage(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "app-faux-radio-btn", [], null, null, null, i1.View_FauxRadioBtnComponent_0, i1.RenderType_FauxRadioBtnComponent)), i0.ɵdid(3, 49152, null, 0, i2.FauxRadioBtnComponent, [], { selected: [0, "selected"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.isLanguageSelected(_v.context.$implicit); _ck(_v, 3, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLanguageSelected(_v.context.$implicit); _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.context.$implicit.label; _ck(_v, 1, 0, currVal_1); }); }
export function View_LangSettingComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "p-dropdown", [["auto", "language-dropdown"], ["styleClass", "dropdown-language-setting"]], [[2, "ui-inputwrapper-filled", null], [2, "ui-inputwrapper-focus", null]], null, null, i3.View_Dropdown_0, i3.RenderType_Dropdown)), i0.ɵprd(5120, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i5.Dropdown]), i0.ɵprd(512, null, i6.DomHandler, i6.DomHandler, []), i0.ɵprd(512, null, i7.ObjectUtils, i7.ObjectUtils, []), i0.ɵdid(4, 13877248, null, 1, i5.Dropdown, [i0.ElementRef, i6.DomHandler, i0.Renderer2, i0.ChangeDetectorRef, i7.ObjectUtils, i0.NgZone], { styleClass: [0, "styleClass"], autoWidth: [1, "autoWidth"], options: [2, "options"] }, null), i0.ɵqud(603979776, 1, { templates: 1 }), (_l()(), i0.ɵand(0, null, null, 1, null, View_LangSettingComponent_1)), i0.ɵdid(7, 16384, [[1, 4]], 0, i8.PrimeTemplate, [i0.TemplateRef], { name: [0, "name"] }, null), (_l()(), i0.ɵand(0, null, null, 1, null, View_LangSettingComponent_2)), i0.ɵdid(9, 16384, [[1, 4]], 0, i8.PrimeTemplate, [i0.TemplateRef], { name: [0, "name"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = "dropdown-language-setting"; var currVal_3 = false; var currVal_4 = _co.languages; _ck(_v, 4, 0, currVal_2, currVal_3, currVal_4); var currVal_5 = "selectedItem"; _ck(_v, 7, 0, currVal_5); var currVal_6 = "item"; _ck(_v, 9, 0, currVal_6); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 4).filled; var currVal_1 = i0.ɵnov(_v, 4).focused; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_LangSettingComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-lang-setting", [], null, null, null, View_LangSettingComponent_0, RenderType_LangSettingComponent)), i0.ɵdid(1, 245760, null, 0, i9.LangSettingComponent, [i10.APP_CONFIG, i11.CookieService, i12.ApiService, i13.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LangSettingComponentNgFactory = i0.ɵccf("app-lang-setting", i9.LangSettingComponent, View_LangSettingComponent_Host_0, {}, { getLanguagesError: "getLanguagesError" }, []);
export { LangSettingComponentNgFactory as LangSettingComponentNgFactory };
