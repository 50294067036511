import { User } from '../models/User';
var UserService = /** @class */ (function () {
    function UserService() {
    }
    Object.defineProperty(UserService.prototype, "user", {
        get: function () {
            var userJSON = JSON.parse(localStorage.getItem('user'));
            var user = new User();
            user.parseUser(userJSON);
            return user;
        },
        set: function (user) {
            localStorage.setItem('user', JSON.stringify(user));
        },
        enumerable: true,
        configurable: true
    });
    return UserService;
}());
export { UserService };
