/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./form-error-message.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./form-error-message.component";
var styles_FormErrorMessageComponent = [i0.styles];
var RenderType_FormErrorMessageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FormErrorMessageComponent, data: {} });
export { RenderType_FormErrorMessageComponent as RenderType_FormErrorMessageComponent };
function View_FormErrorMessageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fas fa-exclamation-triangle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "message-text"]], null, null, null, null, null)), i1.ɵncd(null, 0)], null, null); }
export function View_FormErrorMessageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "error-message"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormErrorMessageComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.show; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_FormErrorMessageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-form-error-message", [], null, null, null, View_FormErrorMessageComponent_0, RenderType_FormErrorMessageComponent)), i1.ɵdid(1, 573440, null, 0, i3.FormErrorMessageComponent, [], null, null)], null, null); }
var FormErrorMessageComponentNgFactory = i1.ɵccf("app-form-error-message", i3.FormErrorMessageComponent, View_FormErrorMessageComponent_Host_0, { show: "show" }, {}, ["*"]);
export { FormErrorMessageComponentNgFactory as FormErrorMessageComponentNgFactory };
