/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./portal-header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./portal-header.component";
var styles_PortalHeaderComponent = [i0.styles];
var RenderType_PortalHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PortalHeaderComponent, data: {} });
export { RenderType_PortalHeaderComponent as RenderType_PortalHeaderComponent };
export function View_PortalHeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "portal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "header-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", "eprove\u2122 surveys"], ["src", "assets/images/logo-eprove-surveys-dark.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["auto", "ses-login-header"], ["class", "header-item title"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, null, null, 0, "div", [["class", "header-item"]], null, null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("Student Portal")); _ck(_v, 4, 0, currVal_0); }); }
export function View_PortalHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-portal-header", [], null, null, null, View_PortalHeaderComponent_0, RenderType_PortalHeaderComponent)), i1.ɵdid(1, 49152, null, 0, i3.PortalHeaderComponent, [], null, null)], null, null); }
var PortalHeaderComponentNgFactory = i1.ɵccf("app-portal-header", i3.PortalHeaderComponent, View_PortalHeaderComponent_Host_0, {}, {}, []);
export { PortalHeaderComponentNgFactory as PortalHeaderComponentNgFactory };
