import { Component, HostListener, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../services/api.service';
import { finalize } from 'rxjs/operators';
import { Subject, interval, Observable } from 'rxjs';
import { sample } from 'rxjs/internal/operators';

@Component({
  selector: 'app-idle-timer',
  template: ''
})

export class IdleTimerComponent implements OnInit {
  readonly TIMER_INTERVAL = 60000; // 1 minute
  readonly SAMPLE_INTERVAL = 1000; // 1 second
  @Input() inactiveMinutes: number;
  userAction$ = new Subject();
  timer: Observable<number>;
  sampleInterval: Observable<number>;
  idleTime: number;

  @HostListener('document:mousemove')
  @HostListener('document:keypress')
  @HostListener('document:click')
  userAction() {
    this.userAction$.next();
  }

  constructor(private router: Router, private apiService: ApiService) {
    this.timer = interval(this.TIMER_INTERVAL);
    this.sampleInterval = interval(this.SAMPLE_INTERVAL);
  }

  ngOnInit() {
    this.idleTime = 0;
    this.subscribeToTimer();
    this.subscribeToUserAction();
  }

  private subscribeToUserAction() {
    this.userAction$.pipe(sample(this.sampleInterval)).subscribe(() => {
      this.idleTime = 0;
    });
  }

  private subscribeToTimer() {
    this.timer.subscribe(() => {
      this.idleTime++;
      this.checkIdleTime();
    });
  }

  private checkIdleTime() {
    if (this.idleTime === this.inactiveMinutes) {
      this.logout();
    }
  }

  private logout() {
    this.apiService.logout().pipe(finalize(() => this.finishLogout())).subscribe();
  }

  private finishLogout() {
    localStorage.clear();
    this.router.navigate(['/login']);
  }

}
