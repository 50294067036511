var TokenService = /** @class */ (function () {
    function TokenService() {
    }
    Object.defineProperty(TokenService.prototype, "token", {
        get: function () {
            return localStorage.getItem('token');
        },
        set: function (token) {
            localStorage.setItem('token', token);
        },
        enumerable: true,
        configurable: true
    });
    return TokenService;
}());
export { TokenService };
