import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppBannerMsgComponent } from './app-banner-msg.component';
import { MessageModule, MessagesModule } from 'primeng/primeng';

@NgModule({
  imports: [
    CommonModule,
    MessagesModule,
    MessageModule
  ],
  declarations: [AppBannerMsgComponent],
  exports: [AppBannerMsgComponent]
})
export class AppBannerMsgModule { }
