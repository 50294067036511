import { Component, OnInit, ViewChild } from '@angular/core';
import { ISurvey } from '../models/ISurvey';
import { ApiService } from '../services/api.service';
import { AppBannerMsgComponent } from '../app-banner-msg/app-banner-msg.component';
import { TokenService } from '../services/token.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements OnInit {
  @ViewChild(AppBannerMsgComponent) bannerMsgComp: AppBannerMsgComponent;
  isLoading: boolean;
  surveys: ISurvey[];

  constructor(private apiService: ApiService,
    private tokenService: TokenService) { }

  ngOnInit() {
    this.isLoading = true;
    this.apiService.getSurveys().subscribe(
      res => {
        this.isLoading = false;
        this.surveys = res;
        this.surveys.map(survey => {
          survey.surveyTakingUrl = survey.surveyTakingUrl.concat(`/${survey.enrollmentId}`);
          survey.surveyTakingUrl = survey.surveyTakingUrl.concat(`?token=${this.tokenService.token}`);
          return survey;
        }
        );
      },
      err => {
        this.isLoading = false;
        this.bannerMsgComp.addErrorMessage('Error retrieving surveys.');
      }
    );
  }

  onGetLanguagesError() {
    const message = 'Server error retrieving available languages. Contact support if you continue to experience this error.';
    this.bannerMsgComp.addErrorMessage(message);
  }

}
