import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { TokenService } from './token.service';
import { UserService } from './user.service';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(private router: Router, private tokenService: TokenService,
              private userService: UserService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const user = this.userService.user;
    if (user && this.tokenService.token) {
      return true;
    } else {
      this.redirectToLoginAndClearStorage();
      return false;
    }
  }

  private redirectToLoginAndClearStorage(): void {
    localStorage.clear();
    this.router.navigate(['/login']);
  }
}
