<app-idle-timer [inactiveMinutes]="20"></app-idle-timer>
<app-portal-header></app-portal-header>
<app-banner-msg></app-banner-msg>
<div class="main-content">
  <app-lang-setting (getLanguagesError)="onGetLanguagesError()"></app-lang-setting>
  <div class="section-centered width-420px">
    <div class="auth-title">
      <i class="eleot-icon icon-lock branded"></i>
      <span>{{"Secure Student Surveys" | translate}}</span>
    </div>
    <div class="subtitle">{{"Please confirm your information" | translate}}</div>
    <ul auto="info-container" class="style-reset">
		<li *ngFor="let studentField of user.studentFields">
			<span>{{studentField.name | translate}}</span>
			<span>{{studentField.value}}</span>
		</li>
    </ul>
    <div class="action-context">{{"Is this you?" | translate}}</div>
    <div class="flex space-between btn-container">
      <button auto="info-no-btn" class="button-cancel" (click)="showDialog = true">{{"No" | translate}}</button>
      <button auto="info-yes-btn" (click)="onConfirmInformation()">{{"Yes" | translate}}</button>
    </div>
  </div>
</div>

<p-dialog width="460" [(visible)]="showDialog" [modal]="true" styleClass="ses-dialog">
  <p-header>
    <i class="fas fa-exclamation-triangle"></i>
    <span auto="not-confirmed-modal" >{{"Not Confirmed" | translate}}</span>
  </p-header>
    {{ contactErrorMessage }}
  <p-footer>
    <button auto="not-confirmed-cancel-btn" class="button-cancel" (click)="showDialog = false">{{"Cancel" | translate}}</button>
    <button auto="not-confirmed-backtologin-btn" (click)="onDialogBack()">{{"Back to Login" | translate}}</button>
  </p-footer>
</p-dialog>
