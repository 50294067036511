import { Component, Inject, OnInit, Input } from '@angular/core';
import { MenuItem } from 'primeng/primeng';
import { finalize } from 'rxjs/operators';
import { ApiService } from '../services/api.service';
import { UserService } from '../services/user.service';
import { Router } from '@angular/router';
import { User } from '../models/User';
import { IHeaderAction } from './IHeaderAction';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';

import { APP_CONFIG, AppConfig } from '../app-config.module';


@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss']
})

export class AppHeaderComponent implements OnInit {
  user: User;
  items: MenuItem[];
  @Input() actionLeft: IHeaderAction;

  constructor(@Inject(APP_CONFIG)
    private appConfig: AppConfig,
    private router: Router,
    private translateService: TranslateService,
    private userService: UserService,
    private apiService: ApiService,
    private cookieService: CookieService
  ) { }

  ngOnInit() {
    this.user = this.userService.user;
    this.initMenuItems();
    this.translateService.onLangChange.subscribe(() => {
      this.initMenuItems();
    });
  }

  onLogout() {
    this.apiService.logout().pipe(finalize(() => this.finishLogout())).subscribe();
  }

  private initMenuItems() {
    this.translateService.get('Logout').subscribe(translated => {
      this.items = [{ icon: 'fas fa-sign-out-alt', label: translated, command: () => this.onLogout() }];
    });
  }

  private finishLogout() {
    this.cookieService.delete(this.appConfig.SES_COOKIE_KEY);
    localStorage.clear();
    this.router.navigate(['/login']);
  }
}
