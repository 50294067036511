import { NgModule, InjectionToken } from '@angular/core';

export let APP_CONFIG = new InjectionToken<AppConfig>('app-config');

export class AppConfig {
  readonly LANGUAGE_COOKIE_KEY = 'lang';
  readonly DEFAULT_LANGUAGE = 'ENGLISH';
  readonly SES_COOKIE_KEY = 'ses_token';
}

export let appConfig = new AppConfig();

@NgModule({
  providers: [{
    provide: APP_CONFIG,
    useValue: appConfig
  }]
})

export class AppConfigModule {}
