/* Modules */
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { DropdownModule } from 'primeng/dropdown';
import { DialogModule } from 'primeng/dialog';
import { MenuModule } from 'primeng/menu';
import { TableModule } from 'primeng/table';

/* Services */
import { ApiService } from './services/api.service';
import { UserService } from './services/user.service';
import { TokenService } from './services/token.service';
import { AuthGuardService } from './services/auth-guard.service';
import { InstitutionsResolveService } from './services/institutions-resolve.service';
import { httpInterceptorProviders } from './http-interceptors';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CookieService } from 'ngx-cookie-service';
import { InstitutionService } from './services/institution.service';

/* Components */
import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { ConfirmUserComponent } from './confirm-user/confirm-user.component';
import { FormErrorMessageComponent } from './form-error-message/form-error-message.component';
import { PortalHeaderComponent } from './portal-header/portal-header.component';
import { LangSettingComponent } from './lang-setting/lang-setting.component';
import { AppHeaderComponent } from './app-header/app-header.component';
import { AppBannerMsgModule } from './app-banner-msg/app-banner-msg.module';
import { AppConfigModule } from './app-config.module';
import { IdleTimerComponent } from './idle-timer/idle-timer.component';
import { FauxRadioBtnComponent } from './app-faux-radio-btn/faux-radio-btn.component';
import { VersionComponent } from './version.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    LoginComponent,
    ConfirmUserComponent,
    FormErrorMessageComponent,
    PortalHeaderComponent,
    LangSettingComponent,
    AppHeaderComponent,
    IdleTimerComponent,
    VersionComponent,
    FauxRadioBtnComponent
  ],
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    DropdownModule,
    DialogModule,
    MenuModule,
    TableModule,
    AppBannerMsgModule,
    AppConfigModule
  ],
  providers: [
    httpInterceptorProviders,
    ApiService,
    AuthGuardService,
    TokenService,
    UserService,
    InstitutionsResolveService,
    CookieService,
    InstitutionService
  ],
  bootstrap: [ AppComponent ]
})

export class AppModule { }
