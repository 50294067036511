/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./faux-radio-btn.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./faux-radio-btn.component";
var styles_FauxRadioBtnComponent = [i0.styles];
var RenderType_FauxRadioBtnComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FauxRadioBtnComponent, data: {} });
export { RenderType_FauxRadioBtnComponent as RenderType_FauxRadioBtnComponent };
export function View_FauxRadioBtnComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "radio-outer"]], [[2, "selected", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "radio-inner"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selected; _ck(_v, 0, 0, currVal_0); }); }
export function View_FauxRadioBtnComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-faux-radio-btn", [], null, null, null, View_FauxRadioBtnComponent_0, RenderType_FauxRadioBtnComponent)), i1.ɵdid(1, 49152, null, 0, i2.FauxRadioBtnComponent, [], null, null)], null, null); }
var FauxRadioBtnComponentNgFactory = i1.ɵccf("app-faux-radio-btn", i2.FauxRadioBtnComponent, View_FauxRadioBtnComponent_Host_0, { selected: "selected" }, {}, []);
export { FauxRadioBtnComponentNgFactory as FauxRadioBtnComponentNgFactory };
