var InstitutionService = /** @class */ (function () {
    function InstitutionService() {
    }
    Object.defineProperty(InstitutionService.prototype, "institution", {
        get: function () {
            return JSON.parse(localStorage.getItem('institution'));
        },
        set: function (institution) {
            localStorage.setItem('institution', JSON.stringify(institution));
        },
        enumerable: true,
        configurable: true
    });
    return InstitutionService;
}());
export { InstitutionService };
