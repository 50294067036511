import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-faux-radio-btn',
  templateUrl: './faux-radio-btn.component.html',
  styleUrls: ['./faux-radio-btn.component.scss']
})

export class FauxRadioBtnComponent {
  @Input() selected: boolean;
}
