/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app-banner-msg.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../node_modules/primeng/components/messages/messages.ngfactory";
import * as i3 from "primeng/components/messages/messages";
import * as i4 from "primeng/components/common/messageservice";
import * as i5 from "@angular/common";
import * as i6 from "./app-banner-msg.component";
var styles_AppBannerMsgComponent = [i0.styles];
var RenderType_AppBannerMsgComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppBannerMsgComponent, data: { "animation": [{ type: 7, name: "bannerState", definitions: [{ type: 0, name: "inactive", styles: { type: 6, styles: { visibility: "hidden", opacity: 0, height: 0 }, offset: null }, options: undefined }, { type: 0, name: "active", styles: { type: 6, styles: { visibility: "visible", opacity: 1 }, offset: null }, options: undefined }, { type: 1, expr: "inactive => active", animation: { type: 4, styles: null, timings: "500ms ease-in" }, options: null }, { type: 1, expr: "active => inactive", animation: { type: 4, styles: null, timings: "500ms ease-out" }, options: null }], options: {} }] } });
export { RenderType_AppBannerMsgComponent as RenderType_AppBannerMsgComponent };
function View_AppBannerMsgComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p-messages", [["class", "ses-message"]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = (_co.onMessagesClosed($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_Messages_0, i2.RenderType_Messages)), i1.ɵdid(1, 245760, null, 0, i3.Messages, [[2, i4.MessageService]], { value: [0, "value"] }, { valueChange: "valueChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.messages; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AppBannerMsgComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], [[24, "@bannerState", 0]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppBannerMsgComponent_1)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.isMessagesVisible; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.state; _ck(_v, 0, 0, currVal_0); }); }
export function View_AppBannerMsgComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-banner-msg", [], null, null, null, View_AppBannerMsgComponent_0, RenderType_AppBannerMsgComponent)), i1.ɵdid(1, 49152, null, 0, i6.AppBannerMsgComponent, [], null, null)], null, null); }
var AppBannerMsgComponentNgFactory = i1.ɵccf("app-banner-msg", i6.AppBannerMsgComponent, View_AppBannerMsgComponent_Host_0, {}, {}, []);
export { AppBannerMsgComponentNgFactory as AppBannerMsgComponentNgFactory };
