import { StudentField } from './StudentField';

interface IUser {
  dateOfBirth: string;
  externalStudentId: string;
  studentLoginId: string;
  firstName: string;
  gender: string;
  gradeLevel: string;
  institutionName: string;
  lastName: string;
  middleName: string;
  fullName: string;
}

export class User implements IUser {
  dateOfBirth: string;
  externalStudentId: string;
  studentLoginId: string;
  firstName: string;
  gender: string;
  gradeLevel: string;
  institutionName: string;
  lastName: string;
  middleName: string;
  fullName: string;
  studentFields: StudentField[];

  constructor() {
  }

  parseUser(data) {
    this.dateOfBirth = data.dateOfBirth;
    this.externalStudentId = data.externalStudentId;
    this.studentLoginId = data.studentLoginId;
    this.firstName = data.firstName;
    this.middleName = data.middleName;
    this.lastName = data.lastName;
    this.gender = data.gender;
    this.gradeLevel = data.gradeLevel;
    this.institutionName = data.institutionName;
    this.studentFields = data.studentFields;

    for (var sf of this.studentFields) {
		console.log('sf.name = ' + sf.name);
      if (sf.name == "Name:") {
        var nameComponents = sf.value.split(" ");
        if (nameComponents != null && nameComponents.length > 0) {
          this.firstName = nameComponents[0];
          if (nameComponents.length > 1) {
            this.lastName = nameComponents[nameComponents.length - 1];
          }
        }
      }
    }
  }
}
