import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from './api.service';
import { IInstitution } from '../models/IInstitution';

@Injectable()
export class InstitutionsResolveService implements Resolve<any> {
  constructor(private apiService: ApiService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IInstitution[]> {
    return this.apiService.getInstitutions().pipe(map(
      res => {
        return res;
      },
      () => {
        return of(null);
      }
    ));
  }
}
