import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { TokenService } from '../services/token.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class ApiRequestInterceptor implements HttpInterceptor {

  constructor(private tokenService: TokenService, private router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.tokenService.token;
    if (token) {
      request = request.clone({ setHeaders: { Authorization: this.tokenService.token } });
    }
    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => event,
        (error: any) => this.handleError(error)
      )
    );
  }

  private handleError(error: any): void {
    if (error instanceof HttpErrorResponse) {
      if (error.status === 401 && this.router.url !== '/login') {
        this.router.navigate(['/login']);
      }
    }
  }
}
