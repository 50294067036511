/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./idle-timer.component";
import * as i2 from "@angular/router";
import * as i3 from "../services/api.service";
var styles_IdleTimerComponent = [];
var RenderType_IdleTimerComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_IdleTimerComponent, data: {} });
export { RenderType_IdleTimerComponent as RenderType_IdleTimerComponent };
export function View_IdleTimerComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_IdleTimerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-idle-timer", [], null, [["document", "mousemove"], ["document", "keypress"], ["document", "click"]], function (_v, en, $event) { var ad = true; if (("document:mousemove" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).userAction() !== false);
        ad = (pd_0 && ad);
    } if (("document:keypress" === en)) {
        var pd_1 = (i0.ɵnov(_v, 1).userAction() !== false);
        ad = (pd_1 && ad);
    } if (("document:click" === en)) {
        var pd_2 = (i0.ɵnov(_v, 1).userAction() !== false);
        ad = (pd_2 && ad);
    } return ad; }, View_IdleTimerComponent_0, RenderType_IdleTimerComponent)), i0.ɵdid(1, 114688, null, 0, i1.IdleTimerComponent, [i2.Router, i3.ApiService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var IdleTimerComponentNgFactory = i0.ɵccf("app-idle-timer", i1.IdleTimerComponent, View_IdleTimerComponent_Host_0, { inactiveMinutes: "inactiveMinutes" }, {}, []);
export { IdleTimerComponentNgFactory as IdleTimerComponentNgFactory };
